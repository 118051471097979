import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class OrderListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/order/list',
            name: 'list',
            label: '列表'
        }],
    ])

    public orderList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {

        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        const start_date: string = Util.handleStartTimeAndEndTime(params.time, false).startTime
        const end_date: string = Util.handleStartTimeAndEndTime(params.time, false).endTime
        delete params.time
        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
