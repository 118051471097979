













































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import billPrint from "@/components/print.vue";
import OrderListAPI from "@/common/api/orderList";
export const pageApiList = getPageAPIs(OrderListAPI);

const date = new Date();
const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
@Component({
  components: {
    billPrint,
  },
})
export default class UserList extends Vue {
  private buyAddressApi: any = new OrderListAPI();
  private printDialog: boolean = false;


  private form: any = {
    time: [currentDate, currentDate], // 时间选择
  };
  private tableDataTreat: any = [];

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.orderList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private printData: any = {};
  private tableInfo: any = [];
  private count: number = 0;

  private print(data: any): void {
    this.tableInfo = [];
    this.printData = data;
    let obj = {
      product_name: "台球费用",
      num: 1,
      order_price: this.printData.order_price,
    };
    this.tableInfo.push(obj);
    for (let i of this.printData.product_list) {
      for (let x of i.list) {
        this.tableInfo.push(x);
      }
    }

    for (let i of this.printData.assistant_list) {
      let astobj = {
        product_name: i.assistant_name,
        num: 1,
        order_price: i.assistant_price,
      };
      this.tableInfo.push(astobj);
    }
    this.printDialog = true;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单号",
    user_name: "用户昵称",
    phone: "手机号",
    pay_type_str: "支付方式",
    total_money: "总金额",

  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "user_name",
    "phone",
    "pay_type_str",
    "total_money",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const {time, channelId} = form;

    const params: any = {
      channelId,
    };

    if (Array.isArray(time) && time.length === 2) {
      params.startDate = Util.dateTimeUtilnohms(this.form.time[0]);
      params.endDate = Util.dateTimeUtilnohms(this.form.time[1]);
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.buyAddressApi.orderList(
        {...this.form, is_export: 1},
        (res: any) => {
          const start_date: string = Util.dateUtil(this.form.time[0]);
          const end_date: string = Util.dateUtil(this.form.time[1]);
          const filename = `营收报表 ${start_date}到${end_date}.xlsx`;
          // const { list: originList } = res.data || {};
          const originList = res.data.list || [];

          // const {
          //   list: originList
          // } = data || {}

          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                // const { orderNum } = item || {};
                const newItem = {
                  ...(item || {}),
                };

                for (let key in item) {
                  if (typeof item[key] == "number") {
                    newItem[key] = item[key].toString();
                  }
                }

                // newItem.orderNum =
                //   typeof orderNum == "number"
                //     ? orderNum.toString()
                //     : orderNum || "";

                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          // const testData = [
          //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
          // ]

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  /** end 导出excel */
  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.form.time = [currentDate, currentDate]
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private pickerOptions = {
    onPick: ({maxDate, minDate}) => {
      if (minDate && !maxDate) {
        this.$set(this.pickerOptions, 'disabledDate', time => {
          // 计算三个月后的日期
          const threeMonthsLater = new Date(minDate);
          threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
          // 如果计算出的日期超过了实际的三个月后，需要调整到三个月后的最后一天
          const lastDayOfMonth = new Date(threeMonthsLater.getFullYear(), threeMonthsLater.getMonth() + 1, 0);
          if (threeMonthsLater.getDate() > lastDayOfMonth.getDate()) {
            threeMonthsLater.setDate(lastDayOfMonth.getDate());
          }

          return time.getTime() > threeMonthsLater.getTime() || time.getTime() < minDate.getTime();
        });
      } else {
        this.$set(this.pickerOptions, 'disabledDate', null);
      }
    }
  }
}
